// Generated by https://quicktype.io

export interface IOrders {
  _id: string;
  orderItems: OrderItem[];
  address: Address;
  contactNumber: string;
  safePlace: string;
  paymentStatus: string;
  standardTotal: number;
  pricingModelTotal: number;
  finalAmount: number;
  user_data: {
    firstName: string;
    lastName: string;
    email: string;
  };
}

export interface Address {
  address1: string;
  address2: string;
  city: string;
  postCode: string;
  state: string;
  latitude: string;
  longitude: string;
  type: string;
}

export interface OrderItem {
  _id?: string;
  date: string;
  fullMealCount: number;
  basePrice: number;
  menuItems: MenuItem[];
  orderStatus: OrderStatusEnum;
}

export interface MenuItem {
  name: string;
  price: number;
  quantity: number;
  isActive?: boolean;
}

export interface GenericKeyPairString {
  [key: string]: string;
}

export enum OrderStatusEnum {
  placed = 'placed',
  accepted = 'accepted',
  rejected = 'rejected',
  delivered = 'delivered',
  cancelled = 'cancelled',
  refunded = 'refunded',
}
