<div class="card">
  <div class="card-header text-uppercase">Orders</div>
  <div class="card-body">
    <div class="row">
      <!-- Start Date -->
      <div class="col-sm-3 mb-3">
        <div class="form-group">
          <label for="dtStart" class="form-label">Start Date:</label>
          <input
            #dtStart
            id="dtStart"
            class="form-control"
            type="date"
            class="dateadded form-control" />
        </div>
      </div>

      <div class="col-sm-3 mb-3">
        <div class="form-group">
          <label for="dtEnd" class="form-label">End Date:</label>
          <input
            #dtEnd
            id="dtEnd"
            class="form-control"
            type="date"
            class="dateadded form-control" />
        </div>
      </div>

      <!-- Order Status -->
      <div class="col-sm-2 mb-3">
        <div class="form-group">
          <label class="form-label" for="selOrderStatus">Order Status</label>
          <select #selOrderStatus class="form-select" id="selOrderStatus">
            <option value="" disabled selected>Select Order Status</option>
            <option *ngFor="let status of orderStatusArray" [value]="status.value">
              {{ status.key }}
            </option>
          </select>
        </div>
      </div>

      <!-- Get Orders Button -->
      <div class="col-sm-2 mt-4">
        <button class="form-control btn btn-primary" (click)="onGetCancelledOrders()">
          Get Orders
        </button>
      </div>
    </div>

    <!-- Table -->
    <table
      class="table table-striped table-bordered table-hover table-sm"
      datatable
      [dtOptions]="dtOptions"
      [dtTrigger]="dtTrigger">
      <thead>
        <tr>
          <th scope="col">Order ID</th>
          <th scope="col">Name</th>
          <th scope="col">Phone</th>
          <th scope="col">Order Date</th>
          <th scope="col">Full Meal Count</th>
          <th scope="col">Menu Items</th>
          <th scope="col" class="notexport">Order Status</th>
          <th scope="col" class="notexport">Action</th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let order of orders">
          <ng-container *ngFor="let orderItem of order.orderItems">
            <tr>
              <td>{{ order._id }}</td>
              <td>
                {{
                  order.user_data
                    ? (order.user_data.firstName || '') + ' ' + (order.user_data.lastName || '')
                    : ''
                }}
              </td>
              <td>{{ order.contactNumber }}</td>
              <td>{{ orderItem.date }}</td>
              <td>{{ orderItem.fullMealCount }}</td>
              <td>
                <ul>
                  <li *ngFor="let item of orderItem.menuItems">
                    {{ item.name }} ({{ item.quantity }})
                  </li>
                </ul>
              </td>
              <td>
                {{ orderItem.orderStatus }}
              </td>
              <td>
                <button
                  *ngIf="orderItem.orderStatus === 'cancelled' && orderItem._id"
                  class="btn btn-danger"
                  (click)="onRefund($event, order._id, orderItem?._id)">
                  Refund
                </button>
                <button
                  *ngIf="orderItem.orderStatus === 'refunded' && orderItem._id"
                  disabled
                  class="btn btn-primary">
                  Done
                </button>
                <button *ngIf="!orderItem._id" disabled class="btn btn-success">
                  Can't refund
                </button>
              </td>
            </tr>
          </ng-container>
        </ng-container>
      </tbody>
    </table>
  </div>
</div>
