<div class="container d-flex flex-column">
  <app-spinner></app-spinner>
  <div class="row vh-100">
    <div class="col-sm-10 col-md-7 col-lg-5 mx-auto d-table h-100">
      <div class="d-table-cell align-middle">
        <div class="card">
          <div class="card-body">
            <div class="m-sm-3">
              <div class="text-center mt-2 mb-4">
                <div class="text-center mb-3">
                  <!-- <img src="../../assets/img/avatar.png" alt="Fintra Logo" class="img-fluid rounded-circle" width="95" height="95" /> -->
                </div>
                <h1 class="h2">Make My Dabba</h1>
                <p class="lead">Sign in to your account to continue</p>
              </div>
              <form [formGroup]="loginRequest" (submit)="authenticateRequest(loginRequest.value)">
                <div class="mb-3">
                  <label class="form-label" for="txtLoginEmail">Username</label>
                  <input
                    class="form-control form-control-lg"
                    id="txtLoginEmail"
                    type="text"
                    formControlName="email"
                    placeholder="Enter your Email"
                    [ngClass]="{
                      'is-invalid':
                        loginRequest.controls['email'].touched &&
                        loginRequest.controls['email'].errors
                    }" />
                </div>
                <div class="mb-3">
                  <label class="form-label" for="txtLoginPassword">Password</label>
                  <input
                    class="form-control form-control-lg"
                    id="txtLoginPassword"
                    type="password"
                    formControlName="password"
                    name="password"
                    placeholder="Enter your password"
                    [ngClass]="{
                      'is-invalid':
                        loginRequest.controls['password'].touched &&
                        loginRequest.controls['password'].errors
                    }" />
                  <small>
                    <a href="#">Forgot password?</a>
                  </small>
                </div>
                <div>
                  <label class="form-check">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      value="remember-me"
                      name="remember-me"
                      checked />
                    <span class="form-check-label"> Remember me</span>
                  </label>
                </div>
                <div class="text-center mt-3">
                  <button
                    class="btn btn-lg btn-primary"
                    [disabled]="!loginRequest.valid"
                    type="submit">
                    Sign in
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
