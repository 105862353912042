<div class="card">
  <div class="card-header text-uppercase">Category List</div>
  <div class="card-body p-0">
    <table class="table table-striped">
      <thead>
        <tr>
          <th scope="col">#</th>
          <th scope="col">Name</th>
          <th scope="col">Code</th>
          <th scope="col">Created At</th>
          <th scope="col">Action</th>
        </tr>
      </thead>
      <tbody *ngIf="categoryList; else noData">
        <tr *ngFor="let category of categoryList; let i = index">
          <th scope="row">{{ i + 1 }}</th>
          <td>{{ category.name }}</td>
          <td>{{ category.code }}</td>
          <td>{{ category.createdAt | date: 'medium' }}</td>
          <td>
            <a class="btn btn-primary" routerLink="/category/edit/{{ category._id }}">Edit</a>
          </td>
        </tr>
      </tbody>
      <ng-template #noData>
        <tbody>
          <tr>
            <td colspan="4">
              <p class="text-center">No Data Found</p>
            </td>
          </tr>
        </tbody>
      </ng-template>
    </table>
  </div>
</div>
