import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { IOrders, GenericKeyPairString } from './orders.interface';
import { CommonService } from '../../services/app/common.service';
import { DataTableDirective } from 'angular-datatables';

@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.css'],
})
export class OrdersComponent implements OnInit, OnDestroy {
  private readonly _orderStatus: GenericKeyPairString = {
    Placed: 'placed',
    Rejected: 'rejected',
    Delivered: 'delivered',
    Cancelled: 'cancelled',
    Refunded: 'refunded',
  };

  private readonly _paymentStatus: GenericKeyPairString = {
    Succeeded: 'Succeeded',
    // custom status, doesn't come from stripe
    ToBeRefunded: 'ToBeRefunded',
    Refunded: 'Refunded',
  };

  public orderStatusArray = Object.keys(this._orderStatus).map(key => ({
    key,
    value: this._orderStatus[key],
  }));

  public paymentStatusArray = Object.keys(this._paymentStatus).map(key => ({
    key,
    value: this._paymentStatus[key],
  }));

  @ViewChild('dtStart', { static: false }) private dtStartDateInput!: ElementRef;
  @ViewChild('dtEnd', { static: false }) private dtEndDateInput!: ElementRef;
  @ViewChild('selOrderStatus', { static: true }) private selOrderStatus!: ElementRef;
  @ViewChild('selPaymentStatus', { static: true }) private selPaymentStatus!: ElementRef;

  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective | undefined;

  orders: IOrders[] = [];
  dtOptions: any = {};
  dtTrigger: Subject<any> = new Subject<any>();

  constructor(private commonService: CommonService) {}

  ngOnInit(): void {
    this.dtOptions = {
      order: [],
      dom: 'lBfrtip',
      buttons: [
        {
          extend: 'excel',
          filename: 'Orders',
          text: 'Export to XLSX',
          exportOptions: {
            columns: ':not(.notexport)',
          },
        },
        {
          extend: 'csv',
          filename: 'Orders',
          text: 'Export to CSV',
          exportOptions: {
            columns: ':not(.notexport)',
          },
        },
        {
          extend: 'print',
          filename: 'Orders',
          text: 'Print',
          exportOptions: {
            columns: ':not(.notexport)',
          },
        },
      ],
      pagingType: 'full_numbers',
      pageLength: 10,
      lengthMenu: [
        [10, 25, 50, -1],
        [10, 25, 50, 'All'],
      ],
      // createdRow: (row: Node, data: unknown[], dataIndex: number) => {
      //   console.log(row);
      //   console.log(data);
      //   const status = data.includes(this._orderStatus['Placed']);
      //   if (status) {
      //     $(row).addClass('highlight-row'); // Add a class to highlight the row
      //   }
      // },
    };

    this.getOrders();
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  async onGetOrderHistory() {
    const startDate = this.dtStartDateInput.nativeElement.value;
    const endDate = this.dtEndDateInput.nativeElement.value;
    const orderStatus = this.selOrderStatus.nativeElement.value || undefined;
    const paymentStatus = this.selPaymentStatus.nativeElement.value || undefined;

    console.log({ startDate, endDate, orderStatus, paymentStatus });
    const dbInstance = await this.dtElement?.dtInstance;
    dbInstance?.destroy();

    this.getOrders(startDate, endDate, orderStatus, paymentStatus);
  }

  getOrders(startDate?: string, endDate?: string, orderStatus?: string, paymentStatus?: string) {
    this.commonService
      ._getOrders(startDate, endDate, orderStatus, paymentStatus)
      .subscribe(data => {
        this.dtTrigger.next({
          ...this.dtOptions,
        });

        this.orders = data;
      });
  }
}
