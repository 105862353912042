import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormArray, FormControl } from '@angular/forms';
import { CommonService } from '../../services/app/common.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuItem } from '../../datamodels/user.model';
import { routePath } from '../../app-routing.module';
import { IMenuPopulated } from '../../datamodels/IMenuPopulated';
import { MenuItemService } from '../../services/app/menu-item.service';
import { MenuHelper } from '../menu-helper';

@Component({
  selector: 'app-edit-day-menu',
  templateUrl: './edit-day-menu.component.html',
  styleUrls: ['./edit-day-menu.component.css'],
})
export class EditDayMenuComponent implements OnInit {
  id!: string;

  formDayMenu = new FormGroup({
    dayPlan: new FormArray([
      new FormGroup({
        date: new FormControl(),
        mealMenus: new FormArray([
          new FormGroup({
            _id: new FormControl(),
            mealMenuType: new FormControl(),
            menuItems: new FormControl(),
          }),
        ]),
      }),
    ]),
  });

  menuItems!: MenuItem[];

  constructor(
    private formBuilder: FormBuilder,
    private datePipe: DatePipe,
    private commonService: CommonService,
    private menuItemService: MenuItemService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.id = this.route.snapshot.params['id'];
    this.commonService._getMenuById(this.id).subscribe({
      next: (menuPopulated: IMenuPopulated) => {
        const meals = menuPopulated.mealMenus;
        this.formDayMenu = new FormGroup({
          dayPlan: new FormArray([
            new FormGroup({
              date: new FormControl(this.datePipe.transform(menuPopulated.date, 'YYYY-MM-dd')),
              mealMenus: new FormArray(
                meals.map(
                  mm =>
                    new FormGroup({
                      _id: new FormControl(mm._id),
                      mealMenuType: new FormControl(mm.mealMenuType),
                      menuItems: new FormControl(mm.menuItems.map((mi: any) => mi._id)),
                    })
                )
              ),
            }),
          ]),
        });
      },
      error: err => {
        console.error(err);
      },
    });

    this.menuItemService._getMenuItemsPopulatedAndGrouped().subscribe({
      next: resp => {
        this.menuItems = MenuHelper.getMenuCategorized(resp);
      },
      error: error => {
        console.log(error);
      },
    });
  }

  get dayPlan(): FormArray {
    return this.formDayMenu.get('dayPlan') as FormArray;
  }

  mealMenuItems(index: number): FormArray {
    return this.dayPlan.at(index).get('mealMenus') as FormArray;
  }

  private mealsGroup(): FormGroup {
    return this.formBuilder.group({
      _id: [''],
      mealMenuType: [''],
      basePrice: [''],
      menuItems: [''],
    });
  }

  addMealMenuGroup(e: any, index: number) {
    e.preventDefault();
    this.mealMenuItems(index).push(this.mealsGroup());
  }

  removeMealMenuGroup(e: any, dayIndex: number, menuIndex: number) {
    e.preventDefault();
    if (
      confirm(
        'Are you sure you want to remove menu?\nBy doing so, you will loose the selection made'
      )
    ) {
      this.mealMenuItems(dayIndex).removeAt(menuIndex);
    }
  }

  _updatedDayMenu(payload: any) {
    const dayPlan = payload.dayPlan[0];
    this.commonService._updateMenuById(this.id, dayPlan).subscribe({
      next: resp => {
        alert('Menu Updated Successfully!');
        this.router.navigateByUrl(routePath.menu_view);
      },
      error: err => {
        console.log(err.error.message.message);
        alert(err.error.message.message);
      },
    });
  }

  onCancel() {
    if (confirm('Are you sure you want to cancel?')) {
      this.router.navigateByUrl(routePath.menu_view);
    }
  }
}
