<div class="card">
  <div class="card-header text-uppercase">Menu Items</div>
  <div class="card-body p-0">
    <table class="table table-striped">
      <thead>
        <tr>
          <th scope="col">#</th>
          <th scope="col">Name</th>
          <th scope="col">Price</th>
          <th scope="col">Category</th>
          <th scope="col">Spice Level</th>
          <th scope="col">Highlighted</th>
          <th scope="col">Highlighted Text</th>
          <th scope="col">Photos</th>
          <th scope="col">Allergen</th>
          <th scope="col">Created At</th>
          <th scope="col">Action</th>
        </tr>
      </thead>
      <tbody *ngIf="menuItemData; else noData">
        <tr *ngFor="let menuItem of menuItemData; let i = index">
          <th scope="row">{{ i + 1 }}</th>
          <td>{{ menuItem.name }}</td>
          <td>{{ menuItem.price }} &#163;</td>
          <td>{{ menuItem.category.name }}</td>
          <td>{{ getSpiceLevelString(menuItem.spiceLevel) }}</td>
          <td>{{ menuItem.highlighted }}</td>
          <td>{{ menuItem.highlightedText }}</td>
          <td>
            <div class="row">
              <div class="col-2" *ngFor="let photo of menuItem.photos">
                <img [src]="photo.url" alt="img" width="50" height="50" />
              </div>
            </div>
          </td>
          <td>{{ menuItem.allergen }}</td>
          <td>{{ menuItem.createdAt | date: 'medium' }}</td>
          <td>
            <a class="btn btn-primary" routerLink="/menu-item/edit/{{ menuItem._id }}">Edit</a>
            <button class="btn btn-danger" (click)="onDelete(i)">Delete</button>
          </td>
        </tr>
      </tbody>
      <ng-template #noData>
        <tbody>
          <tr>
            <td colspan="11">
              <p class="text-center">No Data Found</p>
            </td>
          </tr>
        </tbody>
      </ng-template>
    </table>
  </div>
</div>
