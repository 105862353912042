<div class="wrapper">
  <nav id="sidebar" class="sidebar">
    <div class="sidebar-content js-simplebar">
      <a class="sidebar-brand" routerLink="/dashboard">
        <span class="align-middle">DASHBOARD</span>
      </a>
      <ul class="sidebar-nav">
        <li class="sidebar-item" routerLinkActive="active">
          <a data-bs-target="#masters" data-bs-toggle="collapse" class="sidebar-link collapsed">
            <i class="align-middle" data-feather="layout"></i>
            <span class="align-middle">Category</span>
          </a>
          <ul
            id="masters"
            class="sidebar-dropdown list-unstyled collapse"
            data-bs-parent="#sidebar">
            <li class="sidebar-item" routerLinkActive="active">
              <a class="sidebar-link" routerLink="/createcategory">Create Category</a>
            </li>
            <li class="sidebar-item" routerLinkActive="active">
              <a class="sidebar-link" routerLink="{{ routePath.category_view }}">View Category</a>
            </li>
          </ul>
        </li>
        <li class="sidebar-item" routerLinkActive="active">
          <a data-bs-target="#menuItems" data-bs-toggle="collapse" class="sidebar-link collapsed">
            <i class="align-middle" data-feather="layout"></i>
            <span class="align-middle">Menu Items</span>
          </a>
          <ul
            id="menuItems"
            class="sidebar-dropdown list-unstyled collapse"
            data-bs-parent="#sidebar">
            <li class="sidebar-item" routerLinkActive="active">
              <a class="sidebar-link" routerLink="createmenuitem">Create Menu Item</a>
            </li>
            <li class="sidebar-item" routerLinkActive="active">
              <a class="sidebar-link" routerLink="viewmenuitems">View All Menu Items</a>
            </li>
          </ul>
        </li>
        <li class="sidebar-item" routerLinkActive="active">
          <a data-bs-target="#menu" data-bs-toggle="collapse" class="sidebar-link collapsed">
            <i class="align-middle" data-feather="layout"></i>
            <span class="align-middle">Menu</span>
          </a>
          <ul id="menu" class="sidebar-dropdown list-unstyled collapse" data-bs-parent="#sidebar">
            <li class="sidebar-item" routerLinkActive="active">
              <a class="sidebar-link" routerLink="{{ routePath.menu_create }}">Create Menu</a>
            </li>
            <li class="sidebar-item" routerLinkActive="active">
              <a class="sidebar-link" routerLink="{{ routePath.menu_view }}">View Menus</a>
            </li>
          </ul>
        </li>
        <li class="sidebar-item" routerLinkActive="active">
          <a data-bs-target="#coupons" data-bs-toggle="collapse" class="sidebar-link collapsed">
            <i class="align-middle" data-feather="layout"></i>
            <span class="align-middle">Discount Coupons</span>
          </a>
          <ul
            id="coupons"
            class="sidebar-dropdown list-unstyled collapse"
            data-bs-parent="#sidebar">
            <li class="sidebar-item" routerLinkActive="active">
              <a class="sidebar-link" routerLink="createcoupon">Create Discount Coupon</a>
            </li>
            <li class="sidebar-item" routerLinkActive="active">
              <a class="sidebar-link" routerLink="{{ routePath.coupon_view }}">View All Coupons</a>
            </li>
          </ul>
        </li>
        <li class="sidebar-item" routerLinkActive="active">
          <a data-bs-target="#orders" data-bs-toggle="collapse" class="sidebar-link collapsed">
            <i class="align-middle" data-feather="layout"></i>
            <span class="align-middle">Orders</span>
          </a>
          <ul id="orders" class="sidebar-dropdown list-unstyled collapse" data-bs-parent="#orders">
            <li class="sidebar-item" routerLinkActive="active">
              <a class="sidebar-link" routerLink="{{ routePath.orders }}">View All</a>
            </li>
            <li class="sidebar-item" routerLinkActive="active">
              <a class="sidebar-link" routerLink="{{ routePath.order_meal_items }}">Item Report</a>
            </li>
            <li class="sidebar-item" routerLinkActive="active">
              <a class="sidebar-link" routerLink="{{ routePath.order_menu_item_cancelled_orders }}"
                >Cancelled Menu Items</a
              >
            </li>
            <li class="sidebar-item" routerLinkActive="active">
              <a class="sidebar-link" routerLink="{{ routePath.order_cancelled_by_user }}"
                >Cancelled Orders</a
              >
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </nav>
  <div class="main">
    <app-spinner></app-spinner>
    <nav class="navbar navbar-expand navbar-light navbar-bg">
      <a class="sidebar-toggle d-flex">
        <i class="hamburger align-self-center"></i>
      </a>
      <form
        class="d-none d-sm-inline-block"
        (keyup.enter)="onSearch(searchInput.value)"
        tabindex="1">
        <div class="input-group input-group-navbar">
          <input
            type="text"
            class="form-control"
            placeholder="Search…"
            aria-label="Search"
            #searchInput />
          <button class="btn" type="button" (click)="onSearch(searchInput.value)">
            <i class="align-middle" data-feather="search"></i>
          </button>
        </div>
      </form>
      <div class="navbar-collapse collapse">
        <ul class="navbar-nav navbar-align">
          <li class="nav-item dropdown">
            <a
              class="nav-icon dropdown-toggle"
              href="#"
              id="alertsDropdown"
              data-bs-toggle="dropdown">
              <div class="position-relative">
                <i class="align-middle" data-feather="bell"></i>
                <span class="indicator">2</span>
              </div>
            </a>
          </li>
          <li class="nav-item dropdown">
            <a
              class="nav-icon dropdown-toggle"
              href="#"
              id="messagesDropdown"
              data-bs-toggle="dropdown">
              <div class="position-relative">
                <i class="align-middle" data-feather="message-square"></i>
              </div>
            </a>
          </li>
          <li class="nav-item dropdown">
            <a
              class="nav-icon dropdown-toggle d-inline-block d-sm-none"
              href="#"
              data-bs-toggle="dropdown">
              <i class="align-middle" data-feather="settings"></i>
            </a>
            <a
              class="nav-link dropdown-toggle d-none d-sm-inline-block"
              href="#"
              data-bs-toggle="dropdown">
              <!-- <img src="../../assets/img/sbilogo.png" height="25" width="40" class="img-fluid rounded me-1" alt="Make My Dabba Logo" /> <span class="text-dark">Login User</span> -->
            </a>
            <div class="dropdown-menu dropdown-menu-end">
              <a class="dropdown-item" href="#"
                ><i class="align-middle me-1" data-feather="user"></i> Profile</a
              >
              <div class="dropdown-divider"></div>
              <a class="dropdown-item" href="#"
                ><i class="align-middle me-1" data-feather="settings"></i> Settings & Privacy</a
              >
              <a class="dropdown-item" href="#"
                ><i class="align-middle me-1" data-feather="help-circle"></i> Help Center</a
              >
              <div class="dropdown-divider"></div>
              <a class="dropdown-item" (keydown)="logOut()" (click)="logOut()" tabindex="1"
                >Log out</a
              >
            </div>
          </li>
        </ul>
      </div>
    </nav>
    <main class="content">
      <div class="container-fluid p-0">
        <div class="row">
          <div class="col-12">
            <router-outlet></router-outlet>
          </div>
        </div>
      </div>
    </main>
    <footer class="footer">
      <div class="container-fluid">
        <div class="row text-muted">
          <div class="col-6 text-start">
            <p class="mb-0">
              <!-- <a href="#" class="d-sm-inline-block"><img src="../../assets/img/avatar.png" class="img-fluid avatar rounded me-2" alt="Make My Dabba Logo" /></a> -->
              <a href="index.html" class="text-muted"
                ><strong class="me-2">Make My Dabba </strong>
                <small class="p-3"> Version 1.0 &copy;All Rights Reserved. </small>
              </a>
            </p>
          </div>
          <div class="col-6 text-end">
            <ul class="list-inline">
              <li class="list-inline-item">
                <a class="text-muted" href="#">Support</a>
              </li>
              <li class="list-inline-item">
                <a class="text-muted" href="#">Help Center</a>
              </li>
              <li class="list-inline-item">
                <a class="text-muted" href="#">Privacy</a>
              </li>
              <li class="list-inline-item">
                <a class="text-muted" href="#">Terms</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  </div>
</div>
