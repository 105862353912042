import { Component, OnInit } from '@angular/core';
import { CommonService } from '@app/services/app/common.service';

@Component({
  selector: 'app-view-category',
  templateUrl: './view-category.component.html',
  styleUrls: ['./view-category.component.css'],
})
export class ViewCategoryComponent implements OnInit {
  constructor(private commonService: CommonService) {}

  categoryList: any;

  ngOnInit(): void {
    this.commonService._getCategoryList().subscribe({
      next: data => {
        console.log(data);
        this.categoryList = data;
      },
      error: error => {
        console.log(error);
        alert(error.error.message.message);
      },
    });
  }
}
