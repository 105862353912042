import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SigninComponent } from './signin/signin.component';
import { PageLayoutComponent } from './page-layout/page-layout.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { CreateCategoryComponent } from './category/create-category/create-category.component';
import { CreateCouponComponent } from './coupons/create-coupon/create-coupon.component';
import { ViewCategoryComponent } from './category/view-category/view-category.component';
import { ViewallCouponsComponent } from './coupons/viewall-coupons/viewall-coupons.component';
import { CreateMenuItemComponent } from './menuitems/create-menu-item/create-menu-item.component';
import { ViewMenuItemsComponent } from './menuitems/view-menu-items/view-menu-items.component';
import { AuthGuard } from './services/guard/auth.guard';
import { CreateDayMenuComponent } from './menu/create-day-menu/create-day-menu.component';
import { ViewDayMenuComponent } from './menu/view-day-menu/view-day-menu.component';
import { EditDayMenuComponent } from './menu/edit-day-menu/edit-day-menu.component';
import { OrdersComponent } from './order/orders/orders.component';
import { OrderMealItemsComponent } from './order/order-meal-items/order-meal-items.component';
import { MenuItemCancelledOrdersComponent } from './order/menu-item-cancelled-orders/menu-item-cancelled-orders';
import { EditMenuItemComponent } from './menuitems/edit-menu-item/edit-menu-item.component';
import { EditCategoryComponent } from './category/edit-category/edit-category.component';
import { EditCouponComponent } from './coupons/edit-coupon/edit-coupon.component';
import { CancelledOrderComponent } from './order/cancelled-order/cancelled-order.component';

export const routePath = {
  dashboard: 'dashboard',

  category_create: 'category/create',
  category_view: 'category/view',
  category_edit: 'category/edit/:id',

  coupon_create: 'coupon/create',
  coupon_view: 'coupon/view',
  coupon_edit: 'coupon/edit/:id',

  menu_view: 'menu/view',
  menu_create: 'menu/create',
  menu_edit: 'menu/edit/:id',

  menu_item_view: 'viewmenuitems',
  menu_item_edit: 'menu-item/edit/:id',

  orders: 'order/view',
  order_meal_items: 'order/meal-items',
  order_menu_item_cancelled_orders: 'order/meal-items-cancelled',
  order_cancelled_by_user: 'order/cancelled-by-user',
};

const routes: Routes = [
  { path: '', redirectTo: 'signin', pathMatch: 'full' },
  { path: 'signin', component: SigninComponent, pathMatch: 'full' },
  {
    path: '',
    component: PageLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: routePath.dashboard,
        component: DashboardComponent,
        pathMatch: 'full',
      },
      {
        path: 'createcategory',
        component: CreateCategoryComponent,
        pathMatch: 'full',
      },
      {
        path: routePath.category_view,
        component: ViewCategoryComponent,
        pathMatch: 'full',
      },
      {
        path: routePath.category_edit,
        component: EditCategoryComponent,
        pathMatch: 'full',
      },
      {
        path: 'createcoupon',
        component: CreateCouponComponent,
        pathMatch: 'full',
      },
      {
        path: routePath.coupon_view,
        component: ViewallCouponsComponent,
        pathMatch: 'full',
      },
      {
        path: routePath.coupon_edit,
        component: EditCouponComponent,
        pathMatch: 'full',
      },
      {
        path: 'createmenuitem',
        component: CreateMenuItemComponent,
        pathMatch: 'full',
      },
      {
        path: routePath.menu_item_view,
        component: ViewMenuItemsComponent,
        pathMatch: 'full',
      },
      {
        path: routePath.menu_create,
        component: CreateDayMenuComponent,
        pathMatch: 'full',
      },
      {
        path: routePath.menu_view,
        component: ViewDayMenuComponent,
        pathMatch: 'full',
      },
      {
        path: routePath.menu_edit,
        component: EditDayMenuComponent,
        pathMatch: 'full',
      },
      {
        path: routePath.orders,
        component: OrdersComponent,
        pathMatch: 'full',
      },
      {
        path: routePath.order_meal_items,
        component: OrderMealItemsComponent,
        pathMatch: 'full',
      },
      {
        path: routePath.order_menu_item_cancelled_orders,
        component: MenuItemCancelledOrdersComponent,
        pathMatch: 'full',
      },
      {
        path: routePath.order_cancelled_by_user,
        component: CancelledOrderComponent,
        pathMatch: 'full',
      },
      {
        path: routePath.menu_item_edit,
        component: EditMenuItemComponent,
        pathMatch: 'full',
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
