import { Component, OnInit } from '@angular/core';
import { IMenuItemPopulated, SpiceLevel } from '../menu-item.interface';
import { MenuItemService } from '../../services/app/menu-item.service';
import { routePath } from '../../app-routing.module';
import { getSpiceLevelString } from '../menu-item.helper';

@Component({
  selector: 'app-view-menu-items',
  templateUrl: './view-menu-items.component.html',
  styleUrls: ['./view-menu-items.component.css'],
})
export class ViewMenuItemsComponent implements OnInit {
  routePath = routePath;
  menuItemData!: IMenuItemPopulated[];

  constructor(private menuItemService: MenuItemService) {}

  ngOnInit(): void {
    this.menuItemService._getAllMenuItems().subscribe({
      next: resp => {
        console.log(resp);
        this.menuItemData = resp;
      },
      error: err => {
        console.log(err);
      },
    });
  }

  onDelete(index: number) {
    const menuItem = this.menuItemData[index];

    if (confirm(`Are you sure you want to delete ${menuItem.name}?`) == true) {
      this.menuItemService._deleteMenuItem(menuItem._id).subscribe({
        next: () => {
          this.menuItemData.splice(index, 1);
        },
        error: err => {
          console.log(err);
        },
      });
    }
  }

  getSpiceLevelString(spiceLevelId: number) {
    return getSpiceLevelString(spiceLevelId);
  }
}
