import { Component, ElementRef, ViewChild } from '@angular/core';
import { CommonService } from '../../services/app/common.service';
import { Subject } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import { IOrders } from '../orders/orders.interface';
import { IOrderMealItems } from './order-meal-items.interface';

@Component({
  selector: 'app-order-meal-items',
  templateUrl: './order-meal-items.component.html',
  styleUrls: ['./order-meal-items.component.css'],
})
export class OrderMealItemsComponent {
  @ViewChild('dtStart', { static: false }) dtStartDateInput!: ElementRef;
  @ViewChild('dtEnd', { static: false }) dtEndDateInput!: ElementRef;

  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective | undefined;

  orderMealItems: IOrderMealItems[] = [];
  dtOptions: any = {};
  dtTrigger: Subject<any> = new Subject<any>();

  constructor(private commonService: CommonService) {}

  ngOnInit(): void {
    this.dtOptions = {
      order: [],
      dom: 'lBfrtip',
      buttons: [
        {
          extend: 'excel',
          filename: () => {
            const initialFileName = 'Order-meal-items';
            return this._getFileName(initialFileName);
          },
          text: 'Export to XLSX',
          exportOptions: {
            columns: ':not(.notexport)',
          },
        },
        {
          extend: 'csv',
          filename: () => {
            const initialFileName = 'Order-meal-items';
            return this._getFileName(initialFileName);
          },
          text: 'Export to CSV',
          exportOptions: {
            columns: ':not(.notexport)',
          },
        },
        {
          extend: 'print',
          filename: () => {
            const initialFileName = 'Order-meal-items';
            return this._getFileName(initialFileName);
          },
          text: 'Print',
          exportOptions: {
            columns: ':not(.notexport)',
          },
        },
      ],
      pagingType: 'full_numbers',
      pageLength: 10,
      lengthMenu: [
        [10, 25, 50, -1],
        [10, 25, 50, 'All'],
      ],
    };

    this.getOrderMealItems();
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  private _getFileName(initialFileName: string) {
    const today = new Date().toISOString().split('T')[0];
    const startDate = this.dtStartDateInput.nativeElement.value;
    const endDate = this.dtEndDateInput.nativeElement.value;

    if (startDate) initialFileName = `${initialFileName}-st_${startDate}`;
    if (endDate) initialFileName = `${initialFileName}-en_${endDate}`;
    if (!(startDate && endDate)) initialFileName = `${initialFileName}-${today}`;

    return initialFileName;
  }

  async onGetOrderMealItems() {
    const startDate = this.dtStartDateInput.nativeElement.value;
    const endDate = this.dtEndDateInput.nativeElement.value;

    const dbInstance = await this.dtElement?.dtInstance;
    dbInstance?.destroy();

    this.getOrderMealItems(startDate, endDate);
  }

  getOrderMealItems(startDate?: string, endDate?: string) {
    this.commonService._getOrderMealItems(startDate, endDate).subscribe(data => {
      this.dtTrigger.next({
        ...this.dtOptions,
      });

      this.orderMealItems = data;
    });
  }
}
