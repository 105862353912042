import { Component } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CouponStaff, DiscountType } from '@app/datamodels/user.model';
import { CommonService } from '@app/services/app/common.service';
import { routePath } from '../../app-routing.module';

@Component({
  selector: 'app-create-coupon',
  templateUrl: './create-coupon.component.html',
  styleUrls: ['./create-coupon.component.css'],
})
export class CreateCouponComponent {
  createCoupon: FormGroup;
  discountType: {
    name: string;
    value: DiscountType;
  }[] = [
    {
      name: 'PERCENTAGE',
      value: DiscountType.PERCENTAGE,
    },
    {
      name: 'FIXED',
      value: DiscountType.FIXED,
    },
  ];

  constructor(
    private formBuilder: FormBuilder,
    private commonService: CommonService,
    private router: Router
  ) {
    this.createCoupon = this.formBuilder.group({
      code: ['', [Validators.required]],
      type: ['', [Validators.required]],
      amount: ['', [Validators.required]],
      minOrderAmount: ['', [Validators.required]],
      startDate: ['', [Validators.required]],
      endDate: ['', [Validators.required]],
      description: ['', [Validators.required]],
      maxUsesPerUser: ['', [Validators.min(0)]],
      uptoMaxAmount: ['', []],
      emailFilterText: ['', []],
    });
  }

  _publishCoupon(payload: CouponStaff) {
    if (this.createCoupon.valid) {
      payload.type = +payload.type;
      payload.amount = +payload.amount;
      payload.minOrderAmount = +payload.minOrderAmount;
      console.log(payload);
      this.commonService._createDiscountByStaff(payload).subscribe({
        next: resp => {
          console.log(resp);
          alert('Coupon Created!!');
          this.router.navigateByUrl(routePath.coupon_view);
        },
        error: err => {
          console.log(err.error.message.message);
        },
      });
    }
  }

  onCancel() {
    this.createCoupon.reset();
  }
}
