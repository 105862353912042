import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import { GenericKeyPairString, IOrders, OrderStatusEnum } from '../orders/orders.interface';
import { OrdersService } from '../../services/app/orders.service';

@Component({
  selector: 'app-cancelled-order',
  templateUrl: './cancelled-order.component.html',
  styleUrls: ['./cancelled-order.component.css'],
})
export class CancelledOrderComponent implements OnInit, OnDestroy {
  private readonly _orderStatus: GenericKeyPairString = {
    Delivered: 'delivered',
    Cancelled: 'cancelled',
    Refunded: 'refunded',
  };

  public orderStatusArray = Object.keys(this._orderStatus).map(key => ({
    key,
    value: this._orderStatus[key],
  }));

  @ViewChild('dtStart', { static: false }) private dtStartDateInput!: ElementRef;
  @ViewChild('dtEnd', { static: false }) private dtEndDateInput!: ElementRef;
  @ViewChild('selOrderStatus', { static: true }) private selOrderStatus!: ElementRef;

  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective | undefined;

  orders: IOrders[] = [];
  dtOptions: any = {};
  dtTrigger: Subject<any> = new Subject<any>();

  constructor(private readonly orderService: OrdersService) {}

  ngOnInit(): void {
    this.dtOptions = {
      order: [],
      dom: 'lBfrtip',
      buttons: [
        {
          extend: 'excel',
          filename: 'Orders',
          text: 'Export to XLSX',
          exportOptions: {
            columns: ':not(.notexport)',
          },
        },
        {
          extend: 'csv',
          filename: 'Orders',
          text: 'Export to CSV',
          exportOptions: {
            columns: ':not(.notexport)',
          },
        },
        {
          extend: 'print',
          filename: 'Orders',
          text: 'Print',
          exportOptions: {
            columns: ':not(.notexport)',
          },
        },
      ],
      pagingType: 'full_numbers',
      pageLength: 10,
      lengthMenu: [
        [10, 25, 50, -1],
        [10, 25, 50, 'All'],
      ],
    };

    this.getCancelledOrders();
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  async onGetCancelledOrders() {
    const startDate = this.dtStartDateInput.nativeElement.value;
    const endDate = this.dtEndDateInput.nativeElement.value;
    const orderStatus = this.selOrderStatus.nativeElement.value || undefined;

    console.log({ startDate, endDate, orderStatus });
    const dbInstance = await this.dtElement?.dtInstance;
    dbInstance?.destroy();

    this.getCancelledOrders(startDate, endDate, orderStatus);
  }

  private getCancelledOrders(startDate?: string, endDate?: string, orderStatus?: string) {
    this.orderService._getUserCancelledOrders(startDate, endDate, orderStatus).subscribe(data => {
      this.dtTrigger.next({
        ...this.dtOptions,
      });

      this.orders = data;
    });
  }

  onRefund(event: Event, orderId: string, mealId?: string) {
    if (confirm('Are you sure you want to refund?')) {
      if (!mealId) {
        console.log('There is no _id for the given meal');
        return;
      }

      this.orderService
        ._updateOrderItemStatus(orderId, mealId, OrderStatusEnum.refunded)
        .subscribe(data => {
          alert('Refunded successfully');
          this.onGetCancelledOrders();
        });
    }
  }
}
