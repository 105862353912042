import { Injectable } from '@angular/core';
import { HttpService } from '../http/http.service';
import { LoginReq } from '@app/datamodels/user.model';
import { environment } from '@environments/environment';
import { BehaviorSubject, Observable, map } from 'rxjs';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  private BASE_URL = environment.apiBaseUrl;

  private userSubject: BehaviorSubject<any | null>;
  public user: Observable<any | null>;

  constructor(
    private httpService: HttpService,
    private router: Router
  ) {
    this.userSubject = new BehaviorSubject(sessionStorage.getItem('accToken'));
    this.user = this.userSubject.asObservable();
  }

  public get userValue() {
    return this.userSubject.value;
  }

  authorizeUser(user: LoginReq) {
    const url = `${this.BASE_URL}/staff/signin`;
    return this.httpService.postService(url, user).pipe(
      map(resp => {
        let data: any = [];
        data = resp;
        sessionStorage.setItem('accToken', data.tokens.accessToken);
        localStorage.setItem('refToken', data.tokens.refreshToken);
        this.userSubject.next(sessionStorage.getItem('accToken'));
        return resp;
      })
    );
  }

  logOut() {
    const url = `${this.BASE_URL}/staff/logout`;
    this.httpService.postService(url, '').subscribe({
      next: resp => {
        console.log(resp);
      },
      error: err => {
        console.log(err);
      },
    });
    localStorage.clear();
    sessionStorage.removeItem('accToken');
    this.userSubject.next(null);
    this.router.navigate(['/']);
  }
}
