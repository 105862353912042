<div class="card">
  <div class="card-header text-uppercase">Create Discount Coupon</div>
  <form [formGroup]="createCoupon" (submit)="_publishCoupon(createCoupon.value)">
    <div class="card-body">
      <div class="row mb-3">
        <div class="col-md-3">
          <div class="form-group">
            <label class="form-label" for="type">Discount Type</label>
            <select class="form-select" formControlName="type">
              <option *ngFor="let discount of discountType" [value]="discount.value">
                {{ discount.name }}
              </option>
            </select>
          </div>
        </div>
        <div class="col-md-3">
          <div class="form-group">
            <label class="form-label" for="code">Code</label>
            <input
              type="text"
              class="form-control"
              placeholder="MMD30"
              formControlName="code"
              [ngClass]="{
                'is-invalid':
                  createCoupon.controls['code'].touched && createCoupon.controls['code'].errors
              }" />
          </div>
        </div>
        <div class="col-md-3">
          <div class="form-group">
            <label class="form-label" for="type">Min OrderAmount</label>
            <input
              type="number"
              class="form-control"
              placeholder="30"
              formControlName="minOrderAmount"
              [ngClass]="{
                'is-invalid':
                  createCoupon.controls['minOrderAmount'].touched &&
                  createCoupon.controls['minOrderAmount'].errors
              }" />
          </div>
        </div>
        <div class="col-md-3">
          <div class="form-group">
            <label class="form-label" for="discountValue">Discount Value</label>
            <input
              type="number"
              class="form-control"
              placeholder="10"
              formControlName="amount"
              [ngClass]="{
                'is-invalid':
                  createCoupon.controls['amount'].touched && createCoupon.controls['amount'].errors
              }" />
          </div>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-md-3">
          <div class="form-group">
            <label class="form-label" for="maxUsesPerUser">Max Uses Per User</label>
            <input
              type="number"
              class="form-control"
              placeholder="1"
              formControlName="maxUsesPerUser"
              [ngClass]="{
                'is-invalid':
                  createCoupon.controls['maxUsesPerUser'].touched &&
                  createCoupon.controls['maxUsesPerUser'].errors
              }" />
          </div>
        </div>
        <div class="col-md-3">
          <div class="form-group">
            <label class="form-label" for="uptoMaxAmount">Upto Max Amount</label>
            <input
              type="number"
              class="form-control"
              placeholder="60"
              formControlName="uptoMaxAmount"
              [ngClass]="{
                'is-invalid':
                  createCoupon.controls['uptoMaxAmount'].touched &&
                  createCoupon.controls['uptoMaxAmount'].errors
              }" />
          </div>
        </div>
        <div class="col-md-3">
          <div class="form-group">
            <label class="form-label" for="startDate">Start Date</label>
            <input
              type="date"
              class="form-control"
              placeholder="Start Date"
              formControlName="startDate"
              [ngClass]="{
                'is-invalid':
                  createCoupon.controls['startDate'].touched &&
                  createCoupon.controls['startDate'].errors
              }" />
          </div>
        </div>
        <div class="col-md-3">
          <div class="form-group">
            <label class="form-label" for="endDate">End Date</label>
            <input
              type="date"
              class="form-control"
              placeholder="End Date"
              formControlName="endDate"
              [ngClass]="{
                'is-invalid':
                  createCoupon.controls['endDate'].touched &&
                  createCoupon.controls['endDate'].errors
              }" />
          </div>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-md-3">
          <div class="form-group">
            <label class="form-label" for="email-id-filter">Email Id Filter</label>
            <input
              class="form-control"
              id="email-id-filter"
              type="text"
              placeholder=".ac.uk"
              formControlName="emailFilterText"
              [ngClass]="{
                'is-invalid':
                  createCoupon.controls['emailFilterText'].touched &&
                  createCoupon.controls['emailFilterText'].errors
              }" />
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label class="form-label" for="description">Description</label>
            <textarea
              class="form-control"
              placeholder="Ex: This coupon is meant for all users"
              formControlName="description"
              [ngClass]="{
                'is-invalid':
                  createCoupon.controls['description'].touched &&
                  createCoupon.controls['description'].errors
              }"></textarea>
          </div>
        </div>
      </div>
    </div>
    <div class="card-footer text-right">
      <button class="btn btn-danger" (click)="onCancel()">Cancel</button>
      <button
        class="btn btn-primary text-right ms-2"
        type="submit"
        [disabled]="!createCoupon.valid">
        Create
      </button>
    </div>
  </form>
</div>
