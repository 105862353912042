<div class="card">
  <div class="card-header text-uppercase">Create Menu Item</div>
  <form [formGroup]="editMenuItem" (submit)="publishMenuItem(editMenuItem.value)">
    <div class="card-body">
      <div class="row mb-3">
        <div class="col-md-4">
          <div class="form-group">
            <label class="form-label" for="name">Name</label>
            <input type="text" class="form-control" placeholder="Name" formControlName="name" />
          </div>
        </div>
        <div class="col-md-4">
          <div [formGroup]="categoryFormGroup" class="form-group">
            <label class="form-label" for="category">Category</label>
            <input type="text" class="form-control" placeholder="Name" formControlName="name" />
          </div>
        </div>
        <div class="col-md-4">
          <div [formGroup]="editMenuItem" class="form-group">
            <label class="form-label" for="spice-level">Spice Level</label>
            <input
              type="text"
              id="spice-level"
              class="form-control"
              placeholder="Spice Level"
              formControlName="spiceLevel" />
          </div>
        </div>
      </div>

      <div class="row mb-3">
        <div class="col-md-4">
          <div class="form-group">
            <label class="form-label" for="price">Price (in GBP)</label>
            <input
              type="number"
              class="form-control"
              placeholder="10.50"
              formControlName="price"
              [ngClass]="{
                'is-invalid':
                  editMenuItem.controls['price'].touched && editMenuItem.controls['price'].errors
              }" />
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group">
            <label class="form-label" for="allergen">Allergen</label>
            <textarea
              class="form-control"
              placeholder="Allergen"
              formControlName="allergen"
              [ngClass]="{
                'is-invalid':
                  editMenuItem.controls['allergen'].touched &&
                  editMenuItem.controls['allergen'].errors
              }"></textarea>
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group">
            <label class="form-label" for="description">Description</label>
            <textarea
              class="form-control"
              placeholder="description"
              formControlName="description"
              [ngClass]="{
                'is-invalid':
                  editMenuItem.controls['description'].touched &&
                  editMenuItem.controls['description'].errors
              }"></textarea>
            <!-- <small class="form-text text-muted">We'll never share your email with anyone else.</small> -->
          </div>
        </div>
      </div>

      <div class="row mb-3">
        <div class="col-md-4">
          <div class="form-check form-switch">
            <input
              class="form-check-input"
              type="checkbox"
              role="switch"
              id="flexSwitchCheckDefault"
              formControlName="highlighted"
              [ngClass]="{
                'is-invalid':
                  editMenuItem.controls['highlighted'].touched &&
                  editMenuItem.controls['highlighted'].errors
              }" />
            <label class="form-check-label" for="flexSwitchCheckDefault">Highlight Item</label>
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group">
            <label class="form-label" for="txtHighlightedText">Highlighted Text</label>
            <input
              class="form-control"
              id="txtHighlightedText"
              type="text"
              placeholder="Highlighted Text"
              formControlName="highlightedText"
              [ngClass]="{
                'is-invalid':
                  editMenuItem.controls['highlightedText'].touched &&
                  editMenuItem.controls['highlightedText'].errors
              }" />
          </div>
        </div>
      </div>
    </div>
    <div class="card-footer text-right">
      <button class="btn btn-danger" (click)="cancelPublish($event)">Cancel</button>
      <button
        class="btn btn-primary text-right ms-2"
        type="submit"
        [disabled]="!editMenuItem.valid">
        <span
          *ngIf="isLoading"
          class="spinner-grow spinner-grow-sm"
          role="status"
          aria-hidden="true"></span>
        Update
      </button>
    </div>
  </form>
</div>
