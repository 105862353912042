import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '@app/services/auth/authentication.service';
import { routePath } from '../app-routing.module';
import { PageLayoutService } from '../services/app/page-layout.service';

@Component({
  selector: 'app-page-layout',
  templateUrl: './page-layout.component.html',
  styleUrls: ['./page-layout.component.css'],
})
export class PageLayoutComponent implements OnInit {
  routePath = routePath;

  constructor(
    private authService: AuthenticationService,
    private pageLayoutService: PageLayoutService
  ) {}

  ngOnInit(): void {}

  logOut() {
    this.authService.logOut();
  }

  onSearch(searchQuery: string) {
    this.pageLayoutService.updateSearchQuery(searchQuery);
  }
}
