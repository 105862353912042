<div class="card">
  <div class="card-header text-uppercase">Create Menu Item</div>
  <form [formGroup]="createMenuItem" (submit)="_publishMenuItem(createMenuItem.value)">
    <div class="card-body">
      <div class="row mb-3">
        <div class="col-md-4">
          <div class="form-group">
            <label class="form-label" for="name">Name</label>
            <input
              type="text"
              class="form-control"
              placeholder="Name"
              formControlName="name"
              [ngClass]="{
                'is-invalid':
                  createMenuItem.controls['name'].touched && createMenuItem.controls['name'].errors
              }" />
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group">
            <label class="form-label" for="category">Category</label>
            <select
              class="form-select"
              formControlName="category"
              [ngClass]="{
                'is-invalid':
                  createMenuItem.controls['category'].touched &&
                  createMenuItem.controls['category'].errors
              }">
              <option *ngFor="let cat of categoryList" [value]="cat._id">
                {{ cat.name }}
              </option>
            </select>
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group">
            <label class="form-label" for="spice-level">Spice Level</label>
            <select
              class="form-select"
              id="spice-level"
              formControlName="spiceLevel"
              [ngClass]="{
                'is-invalid':
                  createMenuItem.controls['spiceLevel'].touched &&
                  createMenuItem.controls['spiceLevel'].errors
              }">
              <option *ngFor="let spice of spiceLevels" [value]="spice.value">
                {{ spice.label }}
              </option>
            </select>
          </div>
        </div>
      </div>

      <div class="row mb-3">
        <div class="col-md-4">
          <div class="form-group">
            <label class="form-label" for="price">Price (in GBP)</label>
            <input
              type="number"
              class="form-control"
              placeholder="10.50"
              formControlName="price"
              [ngClass]="{
                'is-invalid':
                  createMenuItem.controls['price'].touched &&
                  createMenuItem.controls['price'].errors
              }" />
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group">
            <label class="form-label" for="allergen">Allergen</label>
            <textarea
              class="form-control"
              placeholder="Allergen"
              formControlName="allergen"
              [ngClass]="{
                'is-invalid':
                  createMenuItem.controls['allergen'].touched &&
                  createMenuItem.controls['allergen'].errors
              }"></textarea>
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group">
            <label class="form-label" for="description">Description</label>
            <textarea
              class="form-control"
              placeholder="description"
              formControlName="description"
              [ngClass]="{
                'is-invalid':
                  createMenuItem.controls['description'].touched &&
                  createMenuItem.controls['description'].errors
              }"></textarea>
          </div>
        </div>
      </div>

      <div class="row mb-3">
        <div class="col-md-4">
          <div class="form-check form-switch">
            <input
              class="form-check-input"
              type="checkbox"
              role="switch"
              id="flexSwitchCheckDefault"
              formControlName="highlighted"
              [ngClass]="{
                'is-invalid':
                  createMenuItem.controls['highlighted'].touched &&
                  createMenuItem.controls['highlighted'].errors
              }" />
            <label class="form-check-label" for="flexSwitchCheckDefault">Highlight Item</label>
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group">
            <label class="form-label" for="txtHighlightedText">Highlighted Text</label>
            <input
              class="form-control"
              id="txtHighlightedText"
              type="text"
              placeholder="Highlighted Text"
              formControlName="highlightedText"
              [ngClass]="{
                'is-invalid':
                  createMenuItem.controls['highlightedText'].touched &&
                  createMenuItem.controls['highlightedText'].errors
              }" />
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-6" formArrayName="photos">
          <button class="btn btn-info mb-3" (click)="addPhotos($event)">Add Photo</button>
          <div class="form-group">
            <label class="form-label" for="description">Photos</label>
            <div
              class="mb-2 position-relative"
              *ngFor="let photo of photos.controls; let i = index"
              [formGroupName]="i">
              <input
                type="file"
                class="form-control"
                (change)="onFileSelect($event, i)"
                formControlName="item_pictures" />
              <span
                *ngIf="photos.length > 1"
                (click)="removePhoto(i)"
                (keyup)="removePhoto(i)"
                aria-hidden="true"
                class="position-absolute"
                style="
                  padding: 5px 10px;
                  right: 0;
                  top: 0;
                  cursor: pointer;
                  background-color: brown;
                  color: #fff;
                "
                >X</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card-footer text-right">
      <button class="btn btn-danger" (click)="cancelPublish($event)">Cancel</button>
      <button
        class="btn btn-primary text-right ms-2"
        type="submit"
        [disabled]="!createMenuItem.valid">
        Create
      </button>
    </div>
  </form>
</div>
