export interface LoginReq {
  email: string;
  password: string;
}

export interface CategoryModel {
  _id?: string;
  name: string;
  code: string;
}

export interface CouponModel {
  name: string;
  description: string;
  code: string;
  discountType: string | number;
  discountValue: number;
  startDate: string | Date;
  endDate: string | Date;
}

export interface CouponStaff {
  description: string;
  code: string;
  type: number;
  amount: number;
  minOrderAmount: number;
  startDate: string | Date;
  endDate: string | Date;
  maxUsesPerUser: number;
  uptoMaxAmount: number;
  emailFilterText: string;
}

export interface MenuItem {
  _id?: string;
  name: string;
  description: string;
  price: number;
  category: string;
  menuType?: string;
  photos: [];
  highlighted?: boolean;
  highlightedText?: string;
}

export enum DiscountType {
  PERCENTAGE,
  FIXED,
}

export interface Menu {
  date: string | Date;
  mealMenus: MealMenu;
}

export interface MealMenu {
  _id?: string;
  mealMenuType: string;
  menuItems: MenuItem[];
}

export interface Photo {
  _id?: string;
  url: string;
}
