import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { DataTablesModule } from 'angular-datatables';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { SigninComponent } from './signin/signin.component';
import { PageLayoutComponent } from './page-layout/page-layout.component';
import { CreateCategoryComponent } from './category/create-category/create-category.component';
import { ViewCategoryComponent } from './category/view-category/view-category.component';
import { CreateCouponComponent } from './coupons/create-coupon/create-coupon.component';
import { ViewallCouponsComponent } from './coupons/viewall-coupons/viewall-coupons.component';
import { CreateMenuItemComponent } from './menuitems/create-menu-item/create-menu-item.component';
import { ViewMenuItemsComponent } from './menuitems/view-menu-items/view-menu-items.component';
import { JwtInterceptor } from './services/interceptors/jwt.interceptor';
import { CreateDayMenuComponent } from './menu/create-day-menu/create-day-menu.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { DatePipe } from '@angular/common';
import { ViewDayMenuComponent } from './menu/view-day-menu/view-day-menu.component';
import { EditDayMenuComponent } from './menu/edit-day-menu/edit-day-menu.component';
import { OrdersComponent } from './order/orders/orders.component';
import { OrderMealItemsComponent } from './order/order-meal-items/order-meal-items.component';
import { MenuItemCancelledOrdersComponent } from './order/menu-item-cancelled-orders/menu-item-cancelled-orders';
import { EditMenuItemComponent } from './menuitems/edit-menu-item/edit-menu-item.component';
import { EditCategoryComponent } from './category/edit-category/edit-category.component';
import { SpinnerComponent } from './spinner/spinner.component';
import { LoadingInterceptor } from './services/interceptors/loading/loading.interceptor';
import { EditCouponComponent } from './coupons/edit-coupon/edit-coupon.component';
import { CancelledOrderComponent } from './order/cancelled-order/cancelled-order.component';

@NgModule({
  declarations: [
    AppComponent,
    CreateMenuItemComponent,
    CreateCategoryComponent,
    CreateCouponComponent,
    CreateDayMenuComponent,
    DashboardComponent,
    EditDayMenuComponent,
    EditMenuItemComponent,
    MenuItemCancelledOrdersComponent,
    OrdersComponent,
    OrderMealItemsComponent,
    PageLayoutComponent,
    SigninComponent,
    ViewallCouponsComponent,
    ViewMenuItemsComponent,
    ViewDayMenuComponent,
    ViewCategoryComponent,
    EditCategoryComponent,
    SpinnerComponent,
    EditCouponComponent,
    CancelledOrderComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgSelectModule,
    DataTablesModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoadingInterceptor,
      multi: true,
    },
    DatePipe,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
