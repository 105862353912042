<div class="card">
  <div class="card-header text-capitalize">Orders impacted by menu-item cancellation</div>
  <div class="card-body">
    <div class="row">
      <!-- Start Date -->
      <div class="col-sm-3 mb-3">
        <div class="form-group">
          <label for="dtStart" class="form-label">Start Date:</label>
          <input
            #dtStart
            id="dtStart"
            class="form-control"
            type="date"
            class="dateadded form-control" />
        </div>
      </div>

      <div class="col-sm-3 mb-3">
        <div class="form-group">
          <label for="dtEnd" class="form-label">End Date:</label>
          <input
            #dtEnd
            id="dtEnd"
            class="form-control"
            type="date"
            class="dateadded form-control" />
        </div>
      </div>

      <!-- Get Orders Button -->
      <div class="col-sm-2 mt-4">
        <button class="form-control btn btn-primary" (click)="onGetOrderHistory()">
          Get Orders
        </button>
      </div>
    </div>

    <!-- Table -->
    <table
      class="table table-striped table-bordered table-hover table-sm"
      datatable
      [dtOptions]="dtOptions"
      [dtTrigger]="dtTrigger">
      <thead>
        <tr>
          <th scope="col">Order ID</th>
          <th scope="col">Name</th>
          <th scope="col">Phone</th>
          <th scope="col">Email</th>
          <th scope="col">Order Date</th>
          <th scope="col">Deleted Menu Items</th>
          <th scope="col">Address</th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let order of orders">
          <ng-container *ngFor="let orderItem of order.orderItems">
            <tr>
              <td>{{ order._id }}</td>
              <td>
                {{
                  order.user_data
                    ? (order.user_data.firstName || '') + ' ' + (order.user_data.lastName || '')
                    : ''
                }}
              </td>
              <td>{{ order.contactNumber }}</td>
              <td>{{ order.user_data.email }}</td>
              <td>{{ orderItem.date }}</td>
              <td>
                <ul>
                  <li
                    *ngFor="let item of orderItem.menuItems"
                    [ngClass]="item.isActive === false ? 'text-danger' : ''">
                    {{ item.name }} ({{ item.quantity }})
                  </li>
                </ul>
              </td>
              <td>
                {{ order.address.address1 }}, {{ order.address.city }}, {{ order.address.state }}
                {{ order.address.postCode }}
              </td>
            </tr>
          </ng-container>
        </ng-container>
      </tbody>
    </table>
  </div>
</div>
