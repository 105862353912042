import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { HttpService } from '../http/http.service';
import { Observable, forkJoin } from 'rxjs';
import { Router, UrlSerializer } from '@angular/router';
import { IMenuItemPopulated } from '../../menuitems/menu-item.interface';
import { MenuItem } from '@app/datamodels/user.model';
import { IMenuPopulatedAndGrouped } from '../../datamodels/IMenuPopulated';

@Injectable({
  providedIn: 'root',
})
export class MenuItemService {
  private BASE_URL = environment.apiBaseUrl;

  constructor(
    private httpService: HttpService,
    private router: Router,
    private serializer: UrlSerializer
  ) {}

  // MENU ITEM
  _createMenuItem(data: any) {
    const url = `${this.BASE_URL}/item`;
    return this.httpService.postService(url, data);
  }

  _getAllMenuItems(): Observable<IMenuItemPopulated[]> {
    const url = `${this.BASE_URL}/item?populate=true`;
    return this.httpService.getService(url);
  }

  _getAllMenuItemById(id: string): Observable<IMenuItemPopulated> {
    const tree = this.router.createUrlTree(['item', id], {
      queryParams: {
        populate: true,
      },
    });

    const url = `${this.BASE_URL}${tree}`;
    return this.httpService.getService<IMenuItemPopulated>(url);
  }

  _updateMenuItemById(id: string, payload: unknown) {
    const tree = this.router.createUrlTree(['item', id], {
      queryParams: {},
    });

    const url = `${this.BASE_URL}${tree}`;
    return this.httpService.patchService(url, payload);
  }

  _getMenuItemsPopulatedAndGrouped() {
    const url = `${this.BASE_URL}/item?populate=true&grouped=true`;
    return this.httpService.getService<IMenuPopulatedAndGrouped>(url);
  }

  _deleteMenuItem(id: string): Observable<MenuItem> {
    const tree = this.router.createUrlTree(['item', id], {
      queryParams: {},
    });

    const url = `${this.BASE_URL}${tree}`;

    return this.httpService.deleteService<MenuItem>(url);
  }
}
