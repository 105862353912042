<div class="card">
  <div class="card-header text-uppercase">Orders Meal Item Details</div>
  <div class="card-body">
    <div class="row">
      <!-- Start Date -->
      <div class="col-sm-4 mb-3">
        <div class="form-group">
          <label for="dtStart" class="form-label">Start Date:</label>
          <input
            #dtStart
            id="dtStart"
            class="form-control"
            type="date"
            class="dateadded form-control" />
        </div>
      </div>

      <!-- End Date -->
      <div class="col-sm-4 mb-3">
        <div class="form-group">
          <label for="dtEnd" class="form-label">End Date:</label>
          <input
            #dtEnd
            id="dtEnd"
            class="form-control"
            type="date"
            class="dateadded form-control" />
        </div>
      </div>

      <!-- Get Orders Button -->
      <div class="col-sm-4 mt-4">
        <button class="form-control btn btn-primary" (click)="onGetOrderMealItems()">
          Get Item Report
        </button>
      </div>
    </div>

    <!-- Table -->
    <table
      class="table table-striped table-bordered table-hover table-sm"
      datatable
      [dtOptions]="dtOptions"
      [dtTrigger]="dtTrigger">
      <thead>
        <tr>
          <th scope="col">Meal Item</th>
          <th scope="col">Meal Item Count</th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let orderMealItems of orderMealItems">
          <tr>
            <td>{{ orderMealItems._id }}</td>
            <td>{{ orderMealItems.totalQuantity }}</td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </div>
</div>
