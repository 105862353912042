<div class="card">
  <div class="card-header text-uppercase">Coupon List</div>
  <div class="card-body">
    <div class="row">
      <!-- Start Date -->
      <div class="col-sm-4 mb-3">
        <div class="form-group">
          <label for="dtStart" class="form-label">Start Date:</label>
          <input
            #dtStart
            id="dtStart"
            class="form-control"
            type="date"
            class="dateadded form-control" />
        </div>
      </div>

      <div class="col-sm-4 mb-3">
        <div class="form-group">
          <label for="dtEnd" class="form-label">End Date:</label>
          <input
            #dtEnd
            id="dtEnd"
            class="form-control"
            type="date"
            class="dateadded form-control" />
        </div>
      </div>

      <!-- Filter Button -->
      <div class="col-sm-4 mt-4">
        <button class="form-control btn btn-primary" (click)="onCoupons()">Get Coupons</button>
      </div>
    </div>

    <table class="table table-striped" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
      <thead>
        <tr>
          <th scope="col">#</th>
          <th scope="col">Code</th>
          <th scope="col">Discount Type</th>
          <th scope="col">Amount / %</th>
          <th scope="col">Min Order Amount</th>
          <th scope="col">Max Uses/User</th>
          <th scope="col">Max Discount</th>
          <th scope="col">Email Filter</th>
          <th scope="col">Start Date</th>
          <th scope="col">End Date</th>
          <!-- <th scope="col">Action</th> -->
        </tr>
      </thead>
      <tbody *ngIf="couponList; else noData">
        <tr *ngFor="let coupon of couponList; let i = index">
          <th scope="row">{{ i + 1 }}</th>
          <td>{{ coupon.code }}</td>
          <td>{{ getDiscountType(coupon.type) }}</td>
          <td>{{ coupon.amount }}</td>
          <td>{{ coupon.minOrderAmount }}</td>
          <td>{{ coupon.maxUsesPerUser }}</td>
          <td>{{ coupon.uptoMaxAmount }}</td>
          <td>{{ coupon.emailFilterText }}</td>
          <td>{{ coupon.startDate | date: 'medium' }}</td>
          <td>{{ coupon.endDate | date: 'medium' }}</td>
          <!-- <td>
            <a class="btn btn-primary m-2" routerLink="/coupon/edit/{{ coupon._id }}">Edit</a>
            <button class="btn btn-danger" (click)="onDelete(i)">Delete</button>
          </td> -->
        </tr>
      </tbody>
      <ng-template #noData>
        <tbody>
          <tr>
            <td colspan="10">
              <p class="text-center">No Data Found</p>
            </td>
          </tr>
        </tbody>
      </ng-template>
    </table>
  </div>
</div>
