import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthenticationService } from '@app/services/auth/authentication.service';
import { LoginReq } from '@app/datamodels/user.model';
import { first } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.css'],
})
export class SigninComponent implements OnInit {
  loginRequest: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthenticationService,
    private router: Router,
    private actROute: ActivatedRoute
  ) {
    if (this.authService.userValue) {
      this.router.navigate(['dashboard']);
    }
    this.loginRequest = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]],
    });
  }

  ngOnInit(): void {}

  authenticateRequest(payload: LoginReq) {
    if (this.loginRequest.valid) {
      this.authService
        .authorizeUser(payload)
        .pipe(first())
        .subscribe({
          next: () => {
            const returnUrl = this.actROute.snapshot.queryParams['rediructUrl'] || 'dashboard';
            this.router.navigate([returnUrl]);
          },
          error: error => {
            console.log(error);
            alert(error.error.message.message);
          },
        });
    }
  }
}
