import { IMenuPopulatedAndGrouped } from '../datamodels/IMenuPopulated';

export class MenuHelper {
  /**
   * This is the records added in the actual data list
   * So that we can show the category in list of dropdown for better clarity
   *
   * @param menu Response object received from menu item grouped and populated
   * @returns Same list of menu item in a flat array
   * Along with extra records of category information
   */
  static getMenuCategorized(menu: IMenuPopulatedAndGrouped) {
    const mealCategory = Object.keys(menu);

    const updatedMenuItem: any = [];
    mealCategory.forEach((category, index) => {
      const categoryDataArray = menu[category];

      // It's just a HACK
      const addGroupHeader: any = { _id: `00${index}`, name: category, disabled: true };
      categoryDataArray.unshift(addGroupHeader);

      updatedMenuItem.push(categoryDataArray);
    });

    return updatedMenuItem.flat();
  }
}
