import { Component, OnInit } from '@angular/core';
import { CategoryModel } from '../../datamodels/user.model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommonService } from '../../services/app/common.service';
import { ActivatedRoute, Router } from '@angular/router';
import { routePath } from '../../app-routing.module';

@Component({
  selector: 'app-edit-category',
  templateUrl: './edit-category.component.html',
  styleUrls: ['./edit-category.component.css'],
})
export class EditCategoryComponent implements OnInit {
  id!: string;
  category: FormGroup;

  constructor(
    private fromBuilder: FormBuilder,
    private commonService: CommonService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.category = this.fromBuilder.group({
      name: ['', [Validators.required]],
      code: [{ value: '', disabled: true }, [Validators.required]],
    });
  }

  ngOnInit(): void {
    this.id = this.route.snapshot.params['id'];
    this.renderCategory();
  }

  renderCategory() {
    this.commonService._getCategoryById(this.id).subscribe({
      next: resp => {
        this.category.patchValue(resp);
      },
      error: err => {
        console.error(err);
      },
    });
  }

  _updateCategory(payload: CategoryModel) {
    if (this.category.valid) {
      console.log(payload);
      this.commonService._updateCategory(this.id, payload).subscribe({
        next: resp => {
          console.log(resp);
          alert('Created Successfully!');
          this.router.navigateByUrl(routePath.category_view);
        },
        error: error => {
          alert(error.error.message.message);
          console.log(error.error.message.message);
        },
      });
    }
  }

  onCancel(event: MouseEvent) {
    if (confirm('Are you sure you want to cancel?')) {
      this.router.navigateByUrl(routePath.category_view);
    }
  }
}
