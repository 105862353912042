import { Component } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { CategoryModel } from '@app/datamodels/user.model';
import { CommonService } from '@app/services/app/common.service';

@Component({
  selector: 'app-create-category',
  templateUrl: './create-category.component.html',
  styleUrls: ['./create-category.component.css'],
})
export class CreateCategoryComponent {
  createCategory: FormGroup;

  constructor(
    private fromBuilder: FormBuilder,
    private commonService: CommonService
  ) {
    this.createCategory = this.fromBuilder.group({
      name: ['', [Validators.required]],
      code: ['', [Validators.required]],
    });
  }

  _publishCategory(payload: CategoryModel) {
    if (this.createCategory.valid) {
      console.log(payload);
      this.commonService._createcCategoryByStaff(payload).subscribe({
        next: resp => {
          console.log(resp);
          alert('Created Sucessfully!');
          this.createCategory.reset();
        },
        error: error => {
          alert(error.error.message.message);
          this.createCategory.reset();
          console.log(error.error.message.message);
        },
      });
    }
  }

  resetForm(e: any) {
    e.preventDefault();
    this.createCategory.reset();
  }
}
