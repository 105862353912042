import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { CommonService } from '../../services/app/common.service';
import { DataTableDirective } from 'angular-datatables';
import { GenericKeyPairString, IOrders, MenuItem } from '../orders/orders.interface';

@Component({
  selector: 'app-orders',
  templateUrl: './menu-item-cancelled-orders.html',
  styleUrls: ['./menu-item-cancelled-orders.css'],
})
export class MenuItemCancelledOrdersComponent implements OnInit, OnDestroy {
  @ViewChild('dtStart', { static: false }) private dtStartDateInput!: ElementRef;
  @ViewChild('dtEnd', { static: false }) private dtEndDateInput!: ElementRef;

  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective | undefined;

  orders: IOrders[] = [];
  dtOptions: any = {};
  dtTrigger: Subject<any> = new Subject<any>();

  constructor(private commonService: CommonService) {}

  ngOnInit(): void {
    this.dtOptions = {
      order: [],
      dom: 'lBfrtip',
      buttons: [
        {
          extend: 'excel',
          filename: 'Orders',
          text: 'Export to XLSX',
          exportOptions: {
            columns: ':not(.notexport)',
          },
        },
        {
          extend: 'csv',
          filename: 'Orders',
          text: 'Export to CSV',
          exportOptions: {
            columns: ':not(.notexport)',
          },
        },
        {
          extend: 'print',
          filename: 'Orders',
          text: 'Print',
          exportOptions: {
            columns: ':not(.notexport)',
          },
        },
      ],
      pagingType: 'full_numbers',
      pageLength: 10,
      lengthMenu: [
        [10, 25, 50, -1],
        [10, 25, 50, 'All'],
      ],
    };

    this.getOrdersAffectedByMenuItemCancellation();
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  async onGetOrderHistory() {
    const startDate = this.dtStartDateInput.nativeElement.value;
    const endDate = this.dtEndDateInput.nativeElement.value;

    const dbInstance = await this.dtElement?.dtInstance;
    dbInstance?.destroy();

    this.getOrdersAffectedByMenuItemCancellation(startDate, endDate);
  }

  getOrdersAffectedByMenuItemCancellation(startDate?: string, endDate?: string) {
    this.commonService
      ._getOrdersAffectedByMenuItemCancellation(startDate, endDate)
      .subscribe(data => {
        this.dtTrigger.next({
          ...this.dtOptions,
        });

        this.orders = data;
      });
  }
}
