import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { CommonService } from '@app/services/app/common.service';
import { MenuItemService } from '../../services/app/menu-item.service';
import { SpiceLevel } from '../menu-item.interface';

@Component({
  selector: 'app-create-menu-item',
  templateUrl: './create-menu-item.component.html',
  styleUrls: ['./create-menu-item.component.css'],
})
export class CreateMenuItemComponent implements OnInit {
  createMenuItem: FormGroup;
  categoryList: any;
  spiceLevels = SpiceLevel;
  photosList: any = [];
  uploadedImg: any = [];

  constructor(
    private formBuilder: FormBuilder,
    private commonService: CommonService,
    private menuItemService: MenuItemService
  ) {
    this.createMenuItem = this.formBuilder.group({
      name: ['', [Validators.required]],
      description: ['', [Validators.required]],
      allergen: ['', []],
      category: ['', [Validators.required]],
      spiceLevel: [0, [Validators.min(0)]],
      price: ['', [Validators.required, Validators.min(0)]],
      highlighted: '',
      highlightedText: ['', Validators.maxLength(100)],
      photos: this.formBuilder.array([
        this.formBuilder.group({
          item_pictures: [''],
        }),
      ]),
    });
  }

  ngOnInit(): void {
    this.commonService._getCategoryList().subscribe({
      next: data => {
        console.log(data);
        this.categoryList = data;
      },
      error: error => {
        console.log(error);
        alert(error.error.message.message);
      },
    });
  }

  get photos() {
    return this.createMenuItem.get('photos') as FormArray;
  }

  addPhotos(event: MouseEvent) {
    event.preventDefault();
    if (this.photos.length < 4) {
      const photo = this.formBuilder.group({
        item_pictures: [''],
      });
      this.photos.push(photo);
      this.photosList.push(this.photos.length);
    }
  }

  removePhoto(i: number) {
    this.photos.removeAt(i);
    if (this.photosList[i]) {
      this.photosList.splice(i, 1);
    }
    console.log(this.photosList);
  }

  _publishMenuItem(payload: any) {
    if (this.createMenuItem.valid) {
      this.uploadImages(this.photosList)
        .then(async resp => {
          console.log(resp);
          payload.photos = [];
          payload.photos = resp;
          payload.spiceLevel = Number(payload.spiceLevel);
          await this.saveMenuItem(payload);
        })
        .then((resp: any) => {
          window.alert('Menu item created successfully');
        })
        .catch(error => {
          console.log(error);
          alert(error.error.message.message);
        });
    }
  }

  cancelPublish(event: any): void {
    event.preventDefault();
  }

  uploadImages(filesList: any) {
    const files = filesList.filter((elm: any) => {
      if (typeof elm === 'object') {
        return elm;
      }
    });
    return new Promise((resolve, reject) => {
      this.commonService._uploadPhotos(files).subscribe({
        next: resp => {
          // resolve(resp)
          const photos: string[] = [];
          this.uploadedImg = resp;
          this.uploadedImg.forEach((element: any) => {
            element.map((el: any) => {
              photos.push(el._id);
            });
          });
          if (photos) {
            resolve(photos);
          }
        },
        error: error => {
          reject(error);
        },
      });
    });
  }

  saveMenuItem(payload: any) {
    return new Promise((resolve, reject) => {
      this.menuItemService._createMenuItem(payload).subscribe({
        next: resp => {
          this.createMenuItem.reset();
          resolve('Menu Item Created!');
          console.log(resp);
        },
        error: error => {
          reject(error);
        },
      });
    });
  }

  onFileSelect(event: any, i: number) {
    console.log(i);
    const photo = event.target.files[0];
    this.photosList.splice(i, 1, photo);
  }
}
