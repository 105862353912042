import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { HttpService } from '../http/http.service';
import { Observable } from 'rxjs';
import { Router, UrlSerializer } from '@angular/router';
import { IOrders, OrderStatusEnum } from '../../order/orders/orders.interface';

@Injectable({
  providedIn: 'root',
})
export class OrdersService {
  private BASE_URL = environment.apiBaseUrl;

  constructor(
    private httpService: HttpService,
    private router: Router,
    private serializer: UrlSerializer
  ) {}

  _getUserCancelledOrders(
    startDate?: string,
    endDate?: string,
    orderStatus?: string
  ): Observable<IOrders[]> {
    const tree = this.router.createUrlTree(['order', 'cancelled'], {
      queryParams: { startDate: startDate, endDate: endDate, orderStatus },
    });

    const url = `${this.BASE_URL}${tree}`;
    return this.httpService.getService<IOrders[]>(url);
  }

  _updateOrderItemStatus(
    orderId: string,
    orderItemId: string,
    orderStatus: OrderStatusEnum
  ): Observable<IOrders[]> {
    const tree = this.router.createUrlTree(['order', orderId, 'items', orderItemId], {
      queryParams: {},
    });

    const url = `${this.BASE_URL}${tree}`;
    return this.httpService.patchService<IOrders[]>(url, { orderStatus: orderStatus });
  }
}
