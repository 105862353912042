import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import ChartsEmbedSDK, { Dashboard } from '@mongodb-js/charts-embed-dom';
import { environment } from '@environments/environment';
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
})
export class DashboardComponent implements OnInit, AfterViewInit {
  private mongoChartsSDK!: ChartsEmbedSDK;
  private mongoDbDashboard!: Dashboard;

  @ViewChild('mongoDbDashboardContainer') dashboardContainer!: ElementRef;

  constructor() {}

  ngOnInit(): void {
    const mongodbDashboardBaseUrl = environment.mongodbDashboardBaseUrl;

    const token = sessionStorage.getItem('accToken');
    this.mongoChartsSDK = new ChartsEmbedSDK({
      baseUrl: mongodbDashboardBaseUrl,
      getUserToken: () => token || '',
      autoRefresh: true,

      // The following two options enable the dashboard to adjust to the container size
      height: '95vh',
      heightMode: 'scale',
      widthMode: 'scale',
    });

    const dashboardId = environment.mongodbDashboardId; // Replace this with your actual dashboard ID
    this.mongoDbDashboard = this.mongoChartsSDK.createDashboard({
      dashboardId: dashboardId,
    });
  }

  ngAfterViewInit(): void {
    if (this.dashboardContainer) {
      this.mongoDbDashboard
        .render(this.dashboardContainer.nativeElement)
        .catch(() => window.alert('Dashboard failed to initialize'));
    }
  }
}
