import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { CommonService } from '@app/services/app/common.service';
import { MenuItemService } from '../../services/app/menu-item.service';
import { MenuHelper } from '../menu-helper';
import { Router } from '@angular/router';
import { routePath } from '../../app-routing.module';

@Component({
  selector: 'app-create-day-menu',
  templateUrl: './create-day-menu.component.html',
  styleUrls: ['./create-day-menu.component.css'],
})
export class CreateDayMenuComponent implements OnInit {
  formDayMenu!: FormGroup;
  currentDate!: string | null;
  menuItems: any;

  constructor(
    private formBuilder: FormBuilder,
    private datePipe: DatePipe,
    private commonService: CommonService,
    private menuItemService: MenuItemService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.formDayMenu = this.formBuilder.group({
      dayPlan: this.formBuilder.array([
        this.formBuilder.group({
          date: ['', [Validators.required]],
          mealMenus: this.formBuilder.array([
            this.formBuilder.group({
              mealMenuType: ['DINNER'], // default to dinner as per requirement
              menuItems: [''],
            }),
          ]),
        }),
      ]),
    });

    this.currentDate = this.datePipe.transform(new Date(), 'YYYY-MM-dd');
    this.menuItemService._getMenuItemsPopulatedAndGrouped().subscribe({
      next: resp => {
        this.menuItems = MenuHelper.getMenuCategorized(resp);
      },
      error: error => {
        console.log(error);
      },
    });
  }

  get dayPlan(): FormArray {
    return this.formDayMenu.get('dayPlan') as FormArray;
  }

  mealMenuItems(index: number): FormArray {
    return this.dayPlan.at(index).get('mealMenus') as FormArray;
  }

  private dayPlanGroup(): FormGroup {
    return this.formBuilder.group({
      date: [''],
      mealMenus: this.formBuilder.array([
        this.formBuilder.group({
          mealMenuType: ['DINNER'],
          menuItems: [''],
        }),
      ]),
    });
  }

  addNewDayPlan(e: any) {
    e.preventDefault();
    this.dayPlan.push(this.dayPlanGroup());
  }

  removeDayPlan(e: any, i: number) {
    e.preventDefault();
    for (let j = 0; j < this.mealMenuItems(i).controls.length; j++) {
      this.mealMenuItems(i).removeAt(j);
    }
    this.dayPlan.removeAt(i);
  }

  _publishDayWiseMenu(payload: any) {
    this.commonService._publishDayWiseMenu(payload.dayPlan).subscribe({
      next: resp => {
        console.log(resp);
        this.formDayMenu.reset();
        for (let i = 0; i < this.dayPlan.controls.length; i++) {
          if (i !== 0) {
            for (let j = 0; j < this.mealMenuItems(i).controls.length; j++) {
              this.mealMenuItems(i).removeAt(j);
            }
            this.dayPlan.removeAt(i);
          }
        }
        alert('Menu Created Successfully!');
      },
      error: err => {
        console.log(err.error.message.message);
        alert(err.error.message.message);
      },
    });
  }

  onCancel() {
    if (confirm('Are you sure you want to cancel?')) {
      this.router.navigateByUrl(routePath.dashboard);
    }
  }
}
