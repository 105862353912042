export interface IMenuItemPopulated {
  _id: string;
  name: string;
  description: string;
  category: Category;
  spiceLevel: number;
  photos: Photo[];
  isActive: boolean;
  createdAt: string;
  updatedAt: string;
  price: number;
  allergen?: string;
  highlighted?: boolean;
  highlightedText?: string;
}

export interface Category {
  name: string;
  code: Code;
}

export enum Code {
  Main = 'MAIN',
  Rice = 'RICE',
  Sides = 'SIDES',
}

export interface Photo {
  _id: string;
  url: string;
}

export const SpiceLevel = [
  { label: 'N/A', value: 0, selected: true },
  { label: 'Low', value: 1 },
  { label: 'Medium', value: 2 },
  { label: 'High', value: 3 },
];
