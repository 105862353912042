import { Component, OnInit } from '@angular/core';
import { CommonService } from '../../services/app/common.service';
import { IMenuPopulated } from '../../datamodels/IMenuPopulated';

@Component({
  selector: 'app-view-day-menu',
  templateUrl: './view-day-menu.component.html',
  styleUrls: ['./view-day-menu.component.css'],
})
export class ViewDayMenuComponent implements OnInit {
  menuData?: IMenuPopulated[];

  constructor(private commonService: CommonService) {}

  ngOnInit(): void {
    this.commonService._getAllMenuPopulated().subscribe({
      next: (resp: IMenuPopulated[]) => {
        console.log('resp', resp);
        resp.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
        resp.forEach(menu => {
          menu.mealMenus = menu.mealMenus.map(mm => ({
            _id: mm._id,
            mealMenuType: mm.mealMenuType,
            menuItems: mm.menuItems,
          }));
        });

        this.menuData = resp;
      },
      error: err => {
        console.log(err);
      },
    });
  }
}
