import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { CommonService } from '@app/services/app/common.service';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { CouponStaff, DiscountType } from '../../datamodels/user.model';

@Component({
  selector: 'app-viewall-coupons',
  templateUrl: './viewall-coupons.component.html',
  styleUrls: ['./viewall-coupons.component.css'],
})
export class ViewallCouponsComponent implements OnInit, OnDestroy {
  constructor(private commonService: CommonService) {}
  @ViewChild('dtStart', { static: false }) private dtStartDateInput!: ElementRef;
  @ViewChild('dtEnd', { static: false }) private dtEndDateInput!: ElementRef;
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective | undefined;

  dtOptions: any = {};
  dtTrigger: Subject<any> = new Subject<any>();

  couponList?: CouponStaff[];
  discountType: { name: string; value: DiscountType }[] = [
    {
      name: 'PERCENTAGE',
      value: DiscountType.PERCENTAGE,
    },
    {
      name: 'FIXED',
      value: DiscountType.FIXED,
    },
  ];

  ngOnInit(): void {
    this.dtOptions = {
      order: [],
      dom: 'lBfrtip',
      buttons: [
        {
          extend: 'excel',
          filename: 'Orders',
          text: 'Export to XLSX',
          exportOptions: {
            columns: ':not(.notexport)',
          },
        },
        {
          extend: 'csv',
          filename: 'Orders',
          text: 'Export to CSV',
          exportOptions: {
            columns: ':not(.notexport)',
          },
        },
        {
          extend: 'print',
          filename: 'Orders',
          text: 'Print',
          exportOptions: {
            columns: ':not(.notexport)',
          },
        },
      ],
      pagingType: 'full_numbers',
      pageLength: 10,
      lengthMenu: [
        [10, 25, 50, -1],
        [10, 25, 50, 'All'],
      ],
    };

    this.getCoupons();
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  onCoupons() {
    const startDate = this.dtStartDateInput.nativeElement.value;
    const endDate = this.dtEndDateInput.nativeElement.value;

    this.getCoupons(startDate, endDate);
  }

  getCoupons(startDate?: string, endDate?: string) {
    this.commonService._getAllCoupons(startDate, endDate).subscribe({
      next: resp => {
        this.dtTrigger.next({
          ...this.dtOptions,
        });

        this.couponList = resp;
      },
      error: error => {
        console.log(error);
      },
    });
  }

  onDelete(index: number) {}

  getDiscountType(value: number) {
    return this.discountType.find(d => d.value === value)?.name;
  }
}
