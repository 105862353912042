<div class="card">
  <div class="card-header text-uppercase">Create Category</div>
  <form [formGroup]="createCategory" (submit)="_publishCategory(createCategory.value)">
    <div class="card-body">
      <div class="row mb-3">
        <div class="col-md-6">
          <div class="form-group">
            <label class="form-label" for="firstName">Name</label>
            <input
              type="text"
              class="form-control"
              placeholder="Name"
              formControlName="name"
              [ngClass]="{
                'is-invalid':
                  createCategory.controls['name'].touched && createCategory.controls['name'].errors
              }" />
            <!-- <small class="form-text text-muted">We'll never share your email with anyone else.</small> -->
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label class="form-label" for="lastName">Code</label>
            <input
              type="text"
              class="form-control"
              placeholder="code"
              formControlName="code"
              [ngClass]="{
                'is-invalid':
                  createCategory.controls['code'].touched && createCategory.controls['code'].errors
              }" />
          </div>
        </div>
      </div>
    </div>
    <div class="card-footer text-right">
      <button class="btn btn-danger" (click)="resetForm($event)">Cancel</button>
      <button
        class="btn btn-primary text-right ms-2"
        type="submit"
        [disabled]="!createCategory.valid">
        Create
      </button>
    </div>
  </form>
</div>
