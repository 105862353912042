import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PageLayoutService {
  private searchQuerySubject = new Subject<string>();
  searchQuery = this.searchQuerySubject.asObservable();

  constructor() {}

  updateSearchQuery(query: string) {
    this.searchQuerySubject.next(query);
  }
}
