<div class="card">
  <div class="card-header text-uppercase">Update Menu</div>
  <form [formGroup]="formDayMenu" (submit)="_updatedDayMenu(formDayMenu.value)">
    <div class="card-body position-relative" formArrayName="dayPlan">
      <div
        class="mb-4"
        style="border: 1px solid #d2d2d2; padding: 15px"
        *ngFor="let group of dayPlan.controls; let num = index"
        [formGroupName]="num">
        <div class="row mb-3">
          <div class="col-md-6">
            <div class="form-group">
              <label class="form-label" for="date">Date</label>
              <input
                type="date"
                class="form-control"
                placeholder="Date"
                formControlName="date"
                [readOnly]="true" />
            </div>
          </div>
        </div>
        <div formArrayName="mealMenus">
          <div
            class="mt-3 pb-3"
            *ngFor="let meal of mealMenuItems(num).controls; let ie = index"
            [formGroupName]="ie"
            bindValue="_id">
            <hr />
            <div class="row mb-3">
              <div class="col-md-4">
                <div class="form-group">
                  <label class="form-label" for="mealMenuType">Menu Type</label>
                  <select class="form-select" formControlName="mealMenuType">
                    <option value="DINNER">DINNER</option>
                  </select>
                </div>
              </div>
              <div class="col-md-2 mt-4" *ngIf="ie === mealMenuItems(num).controls.length - 1">
                <button
                  class="btn btn-primary text-right ms-2"
                  (click)="addMealMenuGroup($event, num)">
                  Add Menu
                </button>
              </div>
              <div class="col-md-2 mt-4" *ngIf="ie >= 1">
                <button
                  class="btn btn-danger text-right ms-2"
                  (click)="removeMealMenuGroup($event, num, ie)">
                  Remove Menu
                </button>
              </div>
            </div>
            <div class="row">
              <div class="form-group">
                <label class="form-label" for="Date">Menu Items</label>
                <ng-select
                  [multiple]="true"
                  [searchable]="true"
                  [closeOnSelect]="false"
                  formControlName="menuItems"
                  [items]="menuItems"
                  bindLabel="name"
                  bindValue="_id">
                </ng-select>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card-footer text-right">
      <button class="btn btn-danger" type="button" (click)="onCancel()">Cancel</button>
      <button class="btn btn-primary text-right ms-2" type="submit">Update</button>
    </div>
  </form>
</div>
