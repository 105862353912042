import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { HttpService } from '../http/http.service';
import { CouponStaff, CategoryModel } from '@app/datamodels/user.model';
import { Observable, forkJoin } from 'rxjs';
import { IMenuPopulated } from '../../datamodels/IMenuPopulated';
import { IOrders } from '../../order/orders/orders.interface';
import { Router, UrlSerializer } from '@angular/router';
import { IOrderMealItems } from '../../order/order-meal-items/order-meal-items.interface';
import { IUpdateCategoryDto } from '../../category/edit-category/edit-category.interface';

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  private BASE_URL = environment.apiBaseUrl;

  constructor(
    private httpService: HttpService,
    private router: Router,
    private serializer: UrlSerializer
  ) {}

  _createcCategoryByStaff(data: CategoryModel) {
    const url = `${this.BASE_URL}/category`;
    return this.httpService.postService(url, data);
  }

  _getCategoryList(): Observable<CategoryModel[]> {
    const url = `${this.BASE_URL}/category`;
    return this.httpService.getService<CategoryModel[]>(url);
  }

  _getCategoryById(id: string): Observable<CategoryModel> {
    const tree = this.router.createUrlTree(['category', id], {
      queryParams: {},
    });

    const url = `${this.BASE_URL}${tree}`;
    return this.httpService.getService<CategoryModel>(url);
  }

  _updateCategory(id: string, data: IUpdateCategoryDto): Observable<CategoryModel> {
    const tree = this.router.createUrlTree(['category', id], {
      queryParams: {},
    });

    const url = `${this.BASE_URL}${tree}`;
    return this.httpService.patchService<CategoryModel>(url, data);
  }

  _createDiscountByStaff(data: CouponStaff) {
    const url = `${this.BASE_URL}/discount`;
    return this.httpService.postService(url, data);
  }

  _getAllCoupons(startDate?: string, endDate?: string): Observable<CouponStaff[]> {
    const tree = this.router.createUrlTree(['discount', 'view', 'all'], {
      queryParams: { startDate: startDate, endDate: endDate },
    });

    const url = `${this.BASE_URL}${tree}`;
    return this.httpService.getService<CouponStaff[]>(url);
  }

  _uploadPhotos(files: any) {
    const url = `${this.BASE_URL}/item/photos`;
    const serveCalls: any = [];
    files.map((file: File) => {
      const formData: FormData = new FormData();
      formData.append('item_pictures', file, file.name);
      serveCalls.push(this.httpService.postFileData(url, formData));
    });
    return forkJoin(serveCalls);
  }

  // MENU
  _publishDayWiseMenu(dayData: any) {
    const url = `${this.BASE_URL}/menu`;
    // return this.httpService.postService(url,data)
    const serverCalls: any = [];
    dayData.map((data: any) => {
      serverCalls.push(this.httpService.postService(url, data));
    });
    return forkJoin(serverCalls);
  }

  _getAllMenuPopulated(): Observable<IMenuPopulated[]> {
    const url = `${this.BASE_URL}/menu/view/staff-view?populate=true&nextAll=true`;
    return this.httpService.getService<IMenuPopulated[]>(url);
  }

  _getMenuById(id: string) {
    const url = `${this.BASE_URL}/menu/${id}?populate=true`;
    return this.httpService.getService<IMenuPopulated>(url);
  }

  _updateMenuById(id: string, menu: unknown) {
    const url = `${this.BASE_URL}/menu/${id}`;
    return this.httpService.putService(url, menu);
  }

  _getOrders(
    startDate?: string,
    endDate?: string,
    orderStatus?: string,
    paymentStatus?: string
  ): Observable<IOrders[]> {
    const tree = this.router.createUrlTree(['order', 'orders'], {
      queryParams: { startDate: startDate, endDate: endDate, orderStatus, paymentStatus },
    });

    const url = `${this.BASE_URL}${tree}`;
    return this.httpService.getService<IOrders[]>(url);
  }

  _getOrdersAffectedByMenuItemCancellation(
    startDate?: string,
    endDate?: string
  ): Observable<IOrders[]> {
    const tree = this.router.createUrlTree(['order', 'item-deleted-report'], {
      queryParams: { startDate: startDate, endDate: endDate },
    });

    const url = `${this.BASE_URL}${tree}`;
    return this.httpService.getService<IOrders[]>(url);
  }

  _getOrderMealItems(startDate?: string, endDate?: string): Observable<IOrderMealItems[]> {
    const tree = this.router.createUrlTree(['order', 'orders', 'item-report'], {
      queryParams: { startDate: startDate, endDate: endDate },
    });

    const url = `${this.BASE_URL}${tree}`;
    return this.httpService.getService<IOrderMealItems[]>(url);
  }
}
