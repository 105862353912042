<div class="card">
  <div class="card-header text-uppercase">Menu Items</div>
  <div class="card-body p-0">
    <table class="table table-sm">
      <thead>
        <tr>
          <th scope="col">Date</th>
          <th scope="col">MealMenu</th>
        </tr>
      </thead>
      <tbody *ngIf="menuData && menuData.length; else noData">
        <tr *ngFor="let menu of menuData">
          <td>{{ menu.date }}</td>
          <td colspan="3">
            <table class="table table-striped table-sm">
              <thead>
                <tr>
                  <th scope="col">Type</th>
                  <th scope="col">Items</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let mealMenu of menu.mealMenus">
                  <td>{{ mealMenu.mealMenuType }}</td>
                  <td colspan="2">
                    <table class="table table-sm">
                      <tbody>
                        <tr
                          [ngClass]="{ 'text-danger': menuItem.highlighted === true }"
                          *ngFor="let menuItem of mealMenu.menuItems">
                          <td>{{ menuItem.name }}</td>
                          <td>{{ menuItem.price }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
          <td>
            <a class="btn btn-primary" routerLink="/menu/edit/{{ menu._id }}">Edit</a>
          </td>
        </tr>
      </tbody>

      <ng-template #noData>
        <tbody>
          <tr>
            <td colspan="5">
              <p class="text-center">No Data Found</p>
            </td>
          </tr>
        </tbody>
      </ng-template>
    </table>
  </div>
</div>
