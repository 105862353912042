// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  mongodbDashboardBaseUrl: 'https://charts.mongodb.com/charts-aaa-kitchen-ldcbs',
  mongodbDashboardId: '4d37dae9-4a7d-4d48-8ae9-58bd94e11d70',
  apiBaseUrl: 'https://devapi.makemydabba.co.uk',
  // apiBaseUrl: 'http://localhost:3000/dev',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
