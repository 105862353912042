import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class HttpService {
  constructor(private http: HttpClient) {}

  postService(url: string, data: any): Observable<HttpResponse<any>> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post<HttpResponse<any>>(`${url}`, data, { headers });
  }

  getService<TResponse>(url: string): Observable<TResponse> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    // .set('Access-Control-Allow-Credentials', 'true');
    return this.http.get<TResponse>(`${url}`, { headers });
  }

  updateService<TResponse>(url: string, data?: any): Observable<TResponse> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.put<TResponse>(`${url}`, data, { headers });
  }

  deleteService<TResponse>(url: string): Observable<TResponse> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.delete<TResponse>(`${url}`, { headers });
  }

  patchService<TResponse>(url: string, data?: any): Observable<TResponse> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.patch<TResponse>(`${url}`, data, { headers });
  }

  postFileData(url: string, data: any): Observable<HttpResponse<any>> {
    const headers = new HttpHeaders();
    return this.http.post<HttpResponse<any>>(`${url}`, data, { headers });
  }

  putService<TResponse>(url: string, data: unknown): Observable<TResponse> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.put<TResponse>(`${url}`, data, { headers });
  }
}
