import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { CommonService } from '@app/services/app/common.service';
import { MenuItemService } from '../../services/app/menu-item.service';
import { ActivatedRoute, Router } from '@angular/router';
import { IMenuItemPopulated } from '../menu-item.interface';
import { routePath } from '../../app-routing.module';
import { getSpiceLevelString } from '../menu-item.helper';

@Component({
  selector: 'app-create-menu-item',
  templateUrl: './edit-menu-item.component.html',
  styleUrls: ['./edit-menu-item.component.css'],
})
export class EditMenuItemComponent implements OnInit {
  isLoading = false;
  id!: string;

  categoryFormGroup = new FormGroup({
    name: new FormControl({ value: '', disabled: true }),
    code: new FormControl({ value: '', disabled: true }),
  });

  editMenuItem: FormGroup = new FormGroup({
    name: new FormControl({ value: '', disabled: true }),
    category: this.categoryFormGroup,
    description: new FormControl(),
    allergen: new FormControl(),
    spiceLevel: new FormControl({ value: '', disabled: true }),
    price: new FormControl(),
    highlighted: new FormControl(),
    highlightedText: new FormControl(),
  });

  constructor(
    private commonService: CommonService,
    private menuItemService: MenuItemService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.id = this.route.snapshot.params['id'];
    this.renderMenuItem();
  }

  renderMenuItem() {
    this.menuItemService._getAllMenuItemById(this.id).subscribe({
      next: (menuItemPopulated: IMenuItemPopulated) => {
        menuItemPopulated.spiceLevel = getSpiceLevelString(menuItemPopulated.spiceLevel);
        this.editMenuItem.patchValue(menuItemPopulated);
      },
      error: err => {
        console.log(err);
      },
    });
  }

  async publishMenuItem(payload: unknown) {
    if (this.editMenuItem.valid) {
      if (confirm('Are you sure you want to MODIFY menu item?')) {
        await this.saveMenuItem(payload);
        this.isLoading = false;
        this.router.navigateByUrl(routePath.menu_item_view);
      }
    }
  }

  async saveMenuItem(payload: unknown) {
    console.log('payload : ', payload);
    return new Promise((resolve, reject) => {
      this.menuItemService._updateMenuItemById(this.id, payload).subscribe({
        next: resp => {
          resolve('Menu Item Updated!');
          this.router.navigateByUrl(routePath.menu_item_view);
        },
        error: error => {
          reject(error);
        },
      });

      setTimeout(resolve, 1000);
    });
  }

  cancelPublish(event: MouseEvent) {
    event.preventDefault();
    if (confirm('Are you sure you want to cancel?')) {
      this.router.navigateByUrl(routePath.menu_item_view);
    }
  }

  getSpiceLevelString(spiceLevelId: number) {
    return getSpiceLevelString(spiceLevelId);
  }
}

// keep images list a separate track
// also keep dummy list of items in form group of image
// while rendering, filter and dont show if url is empty
// upon save, upload new images
// take the ids and urls
// map them to form data
// complete form save
